.join-now-btn {
  background-color: conic-gradient(
    #400e32 0% 12%,
    #a61f69 12% 34%,
    #8cebcb 34% 64%,
    #03d38b 64% 75%,
    #10917b 75% 88%,
    #400e32 88% 100%
  );
}

.waves-bg {
  background-image: url("../images/waves.png");
  height: auto;
}

.benefits-bg {
  background-image: url("../images/banner.png");
}

.bubbles-bg {
  background-image: url("../images/bubbles.png");
}

.candle-bg {
  background-image: url("../images/candlestick2.png");
  min-height: auto;
  object-fit: no-repeat;
}

.candle-dark-bg {
  background-image: url("../images/candledark.png");
  min-height: auto;
  object-fit: no-repeat;
}

.graph-bg {
  background-image: url("../images/graphs.png");
  min-height: auto;
  object-fit: no-repeat;
}
