/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Add your custom styles here */

@font-face {
  font-family: "bill corp nar book";
  src: url("../public/fonts/billcorpnar-book.eot");
  src:
    local("bill corp nar book"),
    local("billcorpnar-book"),
    url("../public/fonts/billcorpnar-book.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-book.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-book.woff") format("woff"),
    url("../public/fonts/billcorpnar-book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar hairline";
  src: url("../public/fonts/billcorpnar-hairline.eot");
  src:
    local("bill corp nar hairline"),
    local("billcorpnar-hairline"),
    url("../public/fonts/billcorpnar-hairline.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-hairline.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-hairline.woff") format("woff"),
    url("../public/fonts/billcorpnar-hairline.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar eight";
  src: url("../public/fonts/billcorpnar-eight.eot");
  src:
    local("bill corp nar eight"),
    local("billcorpnar-eight"),
    url("../public/fonts/billcorpnar-eight.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-eight.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-eight.woff") format("woff"),
    url("../public/fonts/billcorpnar-eight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar bold";
  src: url("../public/fonts/billcorpnar-bold.eot");
  src:
    local("bill corp nar bold"),
    local("billcorpnar-bold"),
    url("../public/fonts/billcorpnar-bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-bold.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-bold.woff") format("woff"),
    url("../public/fonts/billcorpnar-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar light";
  src: url("../public/fonts/billcorpnar-light.eot");
  src:
    local("bill corp nar light"),
    local("billcorpnar-light"),
    url("../public/fonts/billcorpnar-light.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-light.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-light.woff") format("woff"),
    url("../public/fonts/billcorpnar-light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar medium";
  src: url("../public/fonts/billcorpnar-medium.eot");
  src:
    local("bill corp nar medium"),
    local("billcorpnar-medium"),
    url("../public/fonts/billcorpnar-medium.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-medium.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-medium.woff") format("woff"),
    url("../public/fonts/billcorpnar-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar roman";
  src: url("../public/fonts/billcorpnar-roman.eot");
  src:
    local("bill corp nar roman"),
    local("billcorpnar-roman"),
    url("../public/fonts/billcorpnar-roman.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-roman.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-roman.woff") format("woff"),
    url("../public/fonts/billcorpnar-roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar four";
  src: url("../public/fonts/billcorpnar-four.eot");
  src:
    local("bill corp nar four"),
    local("billcorpnar-four"),
    url("../public/fonts/billcorpnar-four.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-four.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-four.woff") format("woff"),
    url("../public/fonts/billcorpnar-four.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar semibold";
  src: url("../public/fonts/billcorpnar-semibold.eot");
  src:
    local("bill corp nar semibold"),
    local("billcorpnar-semibold"),
    url("../public/fonts/billcorpnar-semibold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-semibold.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-semibold.woff") format("woff"),
    url("../public/fonts/billcorpnar-semibold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar extrabold";
  src: url("../public/fonts/billcorpnar-extrabold.eot");
  src:
    local("bill corp nar extrabold"),
    local("billcorpnar-extrabold"),
    url("../public/fonts/billcorpnar-extrabold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-extrabold.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-extrabold.woff") format("woff"),
    url("../public/fonts/billcorpnar-extrabold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar extralight";
  src: url("../public/fonts/billcorpnar-extralight.eot");
  src:
    local("bill corp nar extralight"),
    local("billcorpnar-extralight"),
    url("../public/fonts/billcorpnar-extralight.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-extralight.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-extralight.woff") format("woff"),
    url("../public/fonts/billcorpnar-extralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar ultralight";
  src: url("../public/fonts/billcorpnar-ultralight.eot");
  src:
    local("bill corp nar ultralight"),
    local("billcorpnar-ultralight"),
    url("../public/fonts/billcorpnar-ultralight.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-ultralight.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-ultralight.woff") format("woff"),
    url("../public/fonts/billcorpnar-ultralight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar super";
  src: url("../public/fonts/billcorpnar-super.eot");
  src:
    local("bill corp nar super"),
    local("billcorpnar-super"),
    url("../public/fonts/billcorpnar-super.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-super.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-super.woff") format("woff"),
    url("../public/fonts/billcorpnar-super.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar thin";
  src: url("../public/fonts/billcorpnar-thin.eot");
  src:
    local("bill corp nar thin"),
    local("billcorpnar-thin"),
    url("../public/fonts/billcorpnar-thin.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-thin.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-thin.woff") format("woff"),
    url("../public/fonts/billcorpnar-thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar two";
  src: url("../public/fonts/billcorpnar-two.eot");
  src:
    local("bill corp nar two"),
    local("billcorpnar-two"),
    url("../public/fonts/billcorpnar-two.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-two.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-two.woff") format("woff"),
    url("../public/fonts/billcorpnar-two.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "bill corp nar six";
  src: url("../public/fonts/billcorpnar-six.eot");
  src:
    local("bill corp nar six"),
    local("billcorpnar-six"),
    url("../public/fonts/billcorpnar-six.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/billcorpnar-six.woff2") format("woff2"),
    url("../public/fonts/billcorpnar-six.woff") format("woff"),
    url("../public/fonts/billcorpnar-six.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
