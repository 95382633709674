.review-text {
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.expanded-review-text {
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: clip;
}

.trade-caret {
  color: blue;
}
